import PropTypes from 'prop-types';
import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Event from '../views/pages/Event/Event';
import GridEvent from '../views/pages/Event/GridEvent';
import Users from '../views/pages/users/Users';
import RegisterUser from '../views/pages/users/Register';
import Speakers from '../views/pages/speaker/Speakers';
import RegisterSpeakers from '../views/pages/speaker/Register';
import GridCourses from '../views/pages/courses/GridCourses';
import Courses from '../views/pages/courses/Courses';
import GridAbility from '../views/pages/ability/GridAbility';
import GridClasses from '../views/pages/classes/GridClasses';
import Classe from '../views/pages/classes/Classe';
import Ability from '../views/pages/ability/Ability';
import { Navigate } from 'react-router';
import { useSelector } from 'react-redux';
import GridLevel from 'views/pages/Level/GridLevel';
import Level from 'views/pages/Level/Level';
import GridLibrary from 'views/pages/library/GridLibrary';
import Library from 'views/pages/library/Library';
import GridPublication from 'views/pages/publication/GridPublication';
import Post from 'views/pages/publication/Post';
import Opportunity from 'views/pages/publication/Opportunity';
import HomeComponent from 'views/pages/Home/HomeComponent';
import Announcement from 'views/pages/publication/Advertisement';
import GridQuiz from 'views/pages/quiz/GridQuiz';
import Quiz from 'views/pages/quiz/Quiz';
import NorthStarMetric from 'views/pages/northStarMetric/NorthStarMetric';
import GridHelp from 'views/pages/help/GridHelp';
import Help from 'views/pages/help/Help';

const AdminRouter = ({ children }) => {
    const role = useSelector((state) => state.auth.user.id_role);
    let token = localStorage.getItem('token');

    if (!token) {
        console.log('sem token');
        return <Navigate to="/login" />;
    }
    return token ? role == 1 ? children : <Navigate to="/index" /> : <Navigate to="/login" />;
};
AdminRouter.propTypes = {
    children: PropTypes.object
};
const AdminRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/novo_evento',
            element: (
                <AdminRouter>
                    <Event />
                </AdminRouter>
            )
        },
        {
            path: '/eventos',
            element: (
                <AdminRouter>
                    <GridEvent />
                </AdminRouter>
            )
        },
        {
            path: '/evento/:id/:action',
            element: (
                <AdminRouter>
                    <Event />
                </AdminRouter>
            )
        },
        {
            path: '/index',
            element: (
                <AdminRouter>
                    <HomeComponent />
                </AdminRouter>
            )
        },
        {
            path: '/usuarios',
            element: (
                <AdminRouter>
                    <Users />
                </AdminRouter>
            )
        },
        {
            path: '/novo_usuario',
            element: (
                <AdminRouter>
                    <RegisterUser />
                </AdminRouter>
            )
        },
        {
            path: '/usuario/:id/:action',
            element: (
                <AdminRouter>
                    <RegisterUser />
                </AdminRouter>
            )
        },
        {
            path: '/palestrantes',
            element: (
                <AdminRouter>
                    <Speakers />
                </AdminRouter>
            )
        },
        {
            path: '/novo_palestrante',
            element: (
                <AdminRouter>
                    <RegisterSpeakers />
                </AdminRouter>
            )
        },
        {
            path: '/palestrante/:id/:action',
            element: (
                <AdminRouter>
                    <RegisterSpeakers />
                </AdminRouter>
            )
        },
        {
            path: '/cursos',
            element: (
                <AdminRouter>
                    <GridCourses />
                </AdminRouter>
            )
        },
        {
            path: '/novo_curso',
            element: (
                <AdminRouter>
                    <Courses />
                </AdminRouter>
            )
        },
        {
            path: '/curso/:id/:action',
            element: (
                <AdminRouter>
                    <Courses />
                </AdminRouter>
            )
        },
        {
            path: '/aulas',
            element: (
                <AdminRouter>
                    <GridClasses />
                </AdminRouter>
            )
        },
        {
            path: '/nova_aula',
            element: (
                <AdminRouter>
                    <Classe />
                </AdminRouter>
            )
        },
        {
            path: '/nova_aula/:id',
            element: (
                <AdminRouter>
                    <Classe />
                </AdminRouter>
            )
        },
        {
            path: '/aula/:id/:action',
            element: (
                <AdminRouter>
                    <Classe />
                </AdminRouter>
            )
        },
        {
            path: '/niveis',
            element: (
                <AdminRouter>
                    <GridLevel />
                </AdminRouter>
            )
        },
        {
            path: '/novo_nivel',
            element: (
                <AdminRouter>
                    <Level />
                </AdminRouter>
            )
        },
        {
            path: '/nivel/:id/:action',
            element: (
                <AdminRouter>
                    <Level />
                </AdminRouter>
            )
        },
        {
            path: '/nova_habilidade',
            element: (
                <AdminRouter>
                    <Ability />
                </AdminRouter>
            )
        },
        {
            path: '/habilidades',
            element: (
                <AdminRouter>
                    <GridAbility />
                </AdminRouter>
            )
        },
        {
            path: '/habilidade/:id/:action',
            element: (
                <AdminRouter>
                    <Ability />
                </AdminRouter>
            )
        },
        {
            path: '/biblioteca',
            element: (
                <AdminRouter>
                    <GridLibrary />
                </AdminRouter>
            )
        },
        {
            path: '/novo_arquivo', //cadastro de arquivos na biblioteca
            element: (
                <AdminRouter>
                    <Library />
                </AdminRouter>
            )
        },
        {
            path: '/arquivo/:id/:action',
            element: (
                <AdminRouter>
                    <Library />
                </AdminRouter>
            )
        },
        {
            path: '/publicacoes',
            element: (
                <AdminRouter>
                    <GridPublication />
                </AdminRouter>
            )
        },
        {
            path: '/nova_postagem',
            element: (
                <AdminRouter>
                    <Post />
                </AdminRouter>
            )
        },
        {
            path: '/nova_oportunidade',
            element: (
                <AdminRouter>
                    <Opportunity />
                </AdminRouter>
            )
        },
        {
            path: '/oportunidade/:id/:action',
            element: (
                <AdminRouter>
                    <Opportunity />
                </AdminRouter>
            )
        },
        {
            path: '/postagem/:id/:action',
            element: (
                <AdminRouter>
                    <Post />
                </AdminRouter>
            )
        },
        {
            path: '/novo_anuncio',
            element: (
                <AdminRouter>
                    <Announcement />
                </AdminRouter>
            )
        },
        {
            path: '/anuncio/:id/:action',
            element: (
                <AdminRouter>
                    <Announcement />
                </AdminRouter>
            )
        },
        {
            path: '/questionario',
            element: (
                <AdminRouter>
                    <GridQuiz />
                </AdminRouter>
            )
        },
        {
            path: '/novo_questionario',
            element: (
                <AdminRouter>
                    <Quiz />
                </AdminRouter>
            )
        },
        {
            path: '/questionario/:id/:action',
            element: (
                <AdminRouter>
                    <Quiz />
                </AdminRouter>
            )
        },
        {
            path: '/north_star_metric',
            element: (
                <AdminRouter>
                    <NorthStarMetric />
                </AdminRouter>
            )
        },
        {
            path: '/ajuda',
            element: (
                <AdminRouter>
                    <GridHelp />
                </AdminRouter>
            )
        },
        {
            path: '/nova_ajuda',
            element: (
                <AdminRouter>
                    <Help />
                </AdminRouter>
            )
        },
        {
            path: '/ajuda/:id/:action',
            element: (
                <AdminRouter>
                    <Help />
                </AdminRouter>
            )
        }
    ]
};

export default AdminRoutes;
