import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import { information } from 'services/auth';

// ==============================|| REACT DOM RENDER  ||============================== //
let token = localStorage.getItem('token');

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
};
if (token) {
    information()
        .then((res) => {
            store.dispatch({ type: 'SET_LOGIN', payload: res.data });
        })
        .then(() => {
            render();
        })
        .catch(() => {
            localStorage.removeItem('token');
            render();
        });
} else {
    render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
