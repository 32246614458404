import { useNavigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import SystemRoutes from './SystemRoutes';
import { useSelector } from 'react-redux';
import ClientRoutes from './ClientRoutes';
import AdminRoutes from './AdminRoutes';
import { useEffect } from 'react';
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const role = useSelector((state) => state.auth.user.id_role);
    const navigate = useNavigate();

    useEffect(() => {
        if (!role) {
            navigate('/login');
        }
    }, [role, navigate]);
    const route =
        role == 1 ? [MainRoutes, AdminRoutes, , AuthenticationRoutes, MainRoutes, SystemRoutes] : [AuthenticationRoutes, ClientRoutes];
    return useRoutes(route, config.basename);
}
